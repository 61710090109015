import React, { useEffect, useRef } from "react"
import PageRoot from "../components/PageRoot"
import { useFlexSearch } from "react-use-flexsearch"
import { Input } from "antd"
import QueryString from "query-string"
import { graphql, navigate } from "gatsby"
import NoDataScreen from "../components/NoDataScreen"
import EventureGrid from "../components/EventureGrid"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { devLog, renderCarouselItem, sendSearchEvent } from "../utils"
import { useTranslation } from "react-i18next"

const { Search } = Input

const SearchPage = ({ location, data }) => {
  const { t } = useTranslation()
  const { store, index } = data.localSearchPages
  devLog({ store, index })
  const params = QueryString.parse(location.search)
  const search = params.search
  const searchInputRef = useRef()
  const results = useFlexSearch(search, index, store)

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus()
  }, [])

  const videos = []
  const authors = []
  const courses = []
  const certifications = []
  const webinars = []

  const dataGroup = {
    ["eventure_videos"]: videos,
    ["eventure_courses"]: courses,
    ["eventure_authors"]: authors,
    ["eventure_certifications"]: certifications,
    ["eventure_webinars"]: webinars,
  }

  results.map(item => {
    dataGroup[item.__typename].push(item)
  })

  devLog({ results })

  const handleSearch = async search => {
    sendSearchEvent(search)
    navigate(`?${QueryString.stringify({ search })}`)
  }

  return (
    <PageRoot title={t("label:search")}>
      <ContainerWithPadding size={"large"}>
        <Search
          defaultValue={search}
          className="search-button"
          placeholder={t("message:searchMessage")}
          allowClear
          enterButton={t("button:search")}
          size="large"
          onSearch={handleSearch}
          maxLength={64}
          ref={searchInputRef}
        />
        {!search && <></>}

        {search && (
          <div className="container-results">
            {results.length ? (
              <>
                {videos.length > 0 && (
                  <EventureGrid
                    title={t("label:video")}
                    dataSource={videos}
                    renderItem={renderCarouselItem}
                  />
                )}
                {courses.length > 0 && (
                  <EventureGrid
                    title={t("label:courses")}
                    dataSource={courses}
                    renderItem={renderCarouselItem}
                  />
                )}
                {authors.length > 0 && (
                  <EventureGrid
                    colSettings={{
                      xs: 10,
                      sm: 10,
                      md: 8,
                      lg: 6,
                      xl: 4,
                      xxl: 3,
                    }}
                    title={t("label:authors")}
                    dataSource={authors}
                    renderItem={renderCarouselItem}
                  />
                )}
                {certifications.length > 0 && (
                  <EventureGrid
                    title={t("label:certifications")}
                    dataSource={certifications}
                    renderItem={renderCarouselItem}
                  />
                )}
                {webinars.length > 0 && (
                  <EventureGrid
                    title={t("label:webinars")}
                    dataSource={webinars}
                    renderItem={renderCarouselItem}
                  />
                )}
              </>
            ) : (
              <>
                <NoDataScreen />
              </>
            )}
          </div>
        )}
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query localSearch {
    localSearchPages {
      store
      index
    }
  }
`

export default SearchPage
